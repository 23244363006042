import React from 'react'
import { createGlobalStyle, css } from 'styled-components'
import { CssBaseline } from '@material-ui/core'

/**
 * GlobalStyles will be rendered in the <head></head> part of the page
 * and should only be used to apply global css-settings, not for component-level
 * styling.
 */
const GlobalCss = createGlobalStyle`
  html, body {
    /* taken from typography */
    font-family: sans-serif;
    text-size-adjust: 100%;
    font: 100%/1.75 ${p => p.theme.typography.fontFamily};
    text-rendering: optimizeLegibility;
    font-feature-settings: "kern", "liga", "clig", "calt";
    font-kerning: normal;
    font-stretch: 100%;
    font-style: normal;
    font-variant-caps: normal;
    font-variant-east-asian: normal;
    font-variant-ligatures: normal;
    font-variant-numeric: normal;
    
    /* set here to make rems work properly */
    font-size: ${props => props.theme.typography.body2.fontSize}px;
  }

  a {
    color: ${props => props.theme.palette.primary.main};
    text-decoration: none;

    &:hover, &:active {
      outline-width: 0;
    }

    &:hover {
      color: ${props => props.theme.palette.action.hover};
    }

    &:active {
      color: ${props => props.theme.palette.action.active};
    }
  }

  ol,
  ul {
    margin: 0;
  }

  /**
  * Reset button styles.
  * It takes a bit of work to achieve a neutral look.
  * @see https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/Link/Link.js#L29-L52
  */
  button {
    position: relative;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent; /* Reset default value */
    /* even though we might shouldn't remove it, we disable the focus ring for
    * mouse; touch and keyboard users.
    * @see https://a11yproject.com/posts/never-remove-css-outlines/
    */
    outline: 0;
    border: 0;
    margin: 0; /* Remove the margin in Safari */
    border-radius: 0;
    padding: 0; /* Remove the padding in Firefox */
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none; /* reset */
    
    &:-moz-focus-inner {
      border-style: none; /* Remove Firefox dotted outline. */
    }
  }

  span {
    display: inline-block;
  }

  /* global utility classes */
  .hidden {
    display: none !important;
  }
`

export const underlineAnimation = css`
  display: inline-block;
  position: relative;

  :before {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: 0;
    left: 0;
    border-bottom: 1px solid currentColor;
    transition: all 0.5s;
  }

  :hover:before {
    width: 75%;
    left: 12.5%;
  }
`

/**
 * CssBaseline uses normalise.css styling
 * Why normalise.css and not reset.css => https://stackoverflow.com/a/8357635/1238150
 * @see https://material-ui.com/api/css-baseline/
 */
export const GlobalStyles = () => (
  <>
    <CssBaseline />
    <GlobalCss />
  </>
)
