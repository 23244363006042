// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-fraesen-js": () => import("./../../../src/pages/fraesen.js" /* webpackChunkName: "component---src-pages-fraesen-js" */),
  "component---src-pages-fraesmaschinen-js": () => import("./../../../src/pages/fraesmaschinen.js" /* webpackChunkName: "component---src-pages-fraesmaschinen-js" */),
  "component---src-pages-geschichte-js": () => import("./../../../src/pages/geschichte.js" /* webpackChunkName: "component---src-pages-geschichte-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jobs-js": () => import("./../../../src/pages/jobs.js" /* webpackChunkName: "component---src-pages-jobs-js" */),
  "component---src-pages-komplettfertigung-js": () => import("./../../../src/pages/komplettfertigung.js" /* webpackChunkName: "component---src-pages-komplettfertigung-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-laser-gravieren-js": () => import("./../../../src/pages/laser-gravieren.js" /* webpackChunkName: "component---src-pages-laser-gravieren-js" */),
  "component---src-pages-lasergraviermaschine-js": () => import("./../../../src/pages/lasergraviermaschine.js" /* webpackChunkName: "component---src-pages-lasergraviermaschine-js" */),
  "component---src-pages-leistungen-js": () => import("./../../../src/pages/leistungen.js" /* webpackChunkName: "component---src-pages-leistungen-js" */),
  "component---src-pages-maschinenpark-js": () => import("./../../../src/pages/maschinenpark.js" /* webpackChunkName: "component---src-pages-maschinenpark-js" */),
  "component---src-pages-messtechnik-js": () => import("./../../../src/pages/messtechnik.js" /* webpackChunkName: "component---src-pages-messtechnik-js" */),
  "component---src-pages-referenzen-js": () => import("./../../../src/pages/referenzen.js" /* webpackChunkName: "component---src-pages-referenzen-js" */),
  "component---src-pages-schleifen-js": () => import("./../../../src/pages/schleifen.js" /* webpackChunkName: "component---src-pages-schleifen-js" */),
  "component---src-pages-schleifmaschinen-js": () => import("./../../../src/pages/schleifmaschinen.js" /* webpackChunkName: "component---src-pages-schleifmaschinen-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

