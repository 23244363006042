/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * @see https://www.gatsbyjs.org/docs/browser-apis/
 *
 * Examples
 * @see https://github.com/gatsbyjs/gatsby/blob/v2.15.0-rc.4/examples/using-i18n/wrapPageElement.js
 */
import React from 'react'

import App from './src/app'

// Pass all props (hence the ...props) to the layout component so it has access to things like pageContext or location
export const wrapPageElement = ({ element, props }) => (
  <App {...props}>{element}</App>
)
