/**
 * Rendered for each and every page on both the server and browser
 *
 * @see https://github.com/damassi/gatsby-starter-typescript-rebass-netlifycms/blob/master/src/Boot.tsx
 * @see https://github.com/mui-org/material-ui/tree/v4.9.4/examples/gatsby
 */
import React, { useEffect } from 'react'

import { ThemeProvider } from './utils/theme'
import { GlobalStyles } from './utils/global-styles'

const App = ({ children }) => {
  // hydrate material-ui's css
  // see https://material-ui.com/guides/server-rendering/#the-client-side
  useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side')
    if (jssStyles && jssStyles.parentElement) {
      jssStyles.parentElement.removeChild(jssStyles)
    }
  }, [])

  return (
    <ThemeProvider>
      <>
        <GlobalStyles />
        {children}
      </>
    </ThemeProvider>
  )
}

export default App
