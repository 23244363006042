import React from 'react'
import merge from 'lodash.merge'
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from '@material-ui/core/styles'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import { rem, darken } from 'polished'

const BASE_FONT_SIZE = 16

const PALETTE = {
  // common colors
  white: '#ffffff',
  black: '#000000',
  // main colors
  primary: '#1c3c73',
  secondary: darken(0.2, '#1c3c73'),
  // shades of grey
  grey300: '#eeeeee',
  grey400: '#d9dadb',
  grey500: '#5f6062', // basically black
  grey600: '#3e3d40',
}

// values taken from https://material-ui.com/customization/breakpoints/
const BREAKPOINTS = {
  xs: 0,
  sm: 640, // Small devices (landscape phones)
  md: 960, // Medium devices (tablets)
  lg: 1280, // Large devices (desktops)
  xl: 1540, // max-width of the container
}

/**
 * pxToRem ensures polished's rem returns proper rem based on the
 * BASE_FONT_SIZE.
 *
 * Use pxToRem instead of rem directly!
 */
export const pxToRem = value => rem(value, BASE_FONT_SIZE)

/**
 * Theming should be managed as long as possible with Material-UI's Theming
 * approach.
 * @see https://material-ui.com/customization/theming/
 *
 * Here is the default theme of material-ui:
 * @see https://material-ui.com/customization/default-theme/
 *
 * How to extend Theme in TS:
 * @see https://medium.com/javascript-in-plain-english/extend-material-ui-theme-in-typescript-a462e207131f
 */
const muiTheme = createTheme({
  // https://material-ui.com/customization/breakpoints/
  breakpoints: {
    values: BREAKPOINTS,
  },
  // https://material-ui.com/customization/palette/
  palette: {
    primary: {
      main: PALETTE.primary,
    },
    secondary: {
      main: PALETTE.secondary,
    },
    background: {
      default: PALETTE.grey300,
    },
    common: {
      black: PALETTE.black,
      white: PALETTE.white,
    },
    grey: {
      300: PALETTE.grey300,
      400: PALETTE.grey400,
      500: PALETTE.grey500,
      600: PALETTE.grey600,
    },
    // for <buttons /> and <a />
    action: {
      hover: PALETTE.secondary,
      active: PALETTE.secondary,
    },
    // use for: <span />, <p /> and headlines (H1-H6)
    text: {
      primary: PALETTE.grey500,
      secondary: PALETTE.grey600,
    },
  },
  // https://material-ui.com/customization/typography/
  typography: {
    htmlFontSize: BASE_FONT_SIZE,
    fontFamily: ['Aldrich', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    fontSize: BASE_FONT_SIZE,
    fontWeightRegular: 400, // Regular
    fontWeightMedium: 500, // Medium
    fontWeightBold: 900, // Bold
    h1: {
      fontSize: pxToRem(24), // works on iPhone 5
      fontWeight: 900,
      marginBottom: '24px',
      [`@media screen and (min-width: ${BREAKPOINTS.md}px)`]: {
        fontSize: pxToRem(32),
      },
    },
    h2: {
      fontSize: pxToRem(24),
      fontWeight: 900,
      marginBottom: '20px',
      [`@media screen and (min-width: ${BREAKPOINTS.md}px)`]: {
        fontSize: pxToRem(28),
      },
    },
    h3: {
      fontSize: pxToRem(18),
      fontWeight: 700,
      marginBottom: '20px',
      [`@media screen and (min-width: ${BREAKPOINTS.md}px)`]: {
        fontSize: pxToRem(24),
      },
    },
    h4: {
      fontSize: pxToRem(16),
      fontWeight: 700,
    },
    h5: {
      fontSize: pxToRem(16),
      fontWeight: 700,
    },
    h6: {
      fontSize: pxToRem(16),
      fontWeight: 700,
    },
    // style of elements like a, p and span
    body1: {
      fontSize: BASE_FONT_SIZE,
      lineHeight: '28px',
      marginBottom: '8px',
    },
    // style on the <body> element
    body2: {
      fontSize: BASE_FONT_SIZE,
      lineHeight: '28px',
    },
  },
  overrides: {
    /**
     * overwrite MUI css
     *
     * Docs:
     * @see https://material-ui.com/customization/globals/
     * @see https://material-ui.com/customization/components/#global-css-override
     * @see https://material-ui.com/styles/advanced/#overriding-styles-classes-prop
     *
     * Container:
     * @see https://github.com/mui-org/material-ui/blob/v4.9.2/packages/material-ui/src/Container/Container.js
     */
    MuiContainer: {
      root: {
        paddingLeft: 32, // when default spacing is not changed (spacing = x * 8)
        paddingRight: 32,
        [`@media screen and (min-width: ${BREAKPOINTS.md}px)`]: {
          paddingLeft: 24,
          paddingRight: 24,
        },
      },
      /**
       * required too, as .root is overwritten above
       */
      disableGutters: {
        paddingLeft: '0',
        paddingRight: '0',
      },
    },
  },
})

/**
 * theme contains both the MUI-theme and the custom theme, which can be used
 * throughout the app. `theme` should only be used, when createTheme's object
 * cannot manage custom theming options.
 */
export const theme = merge({}, muiTheme, {})

/**
 * ThemeProvider handles both Material-UI's and Styled-Component's ThemeProvider
 */
export const ThemeProvider = ({ children }) => {
  return (
    <StyledThemeProvider theme={theme}>
      <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
    </StyledThemeProvider>
  )
}
